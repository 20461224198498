import React from "react";
import LessonLayout from "~/layouts/lesson";
import Introduction from "~/layouts/introduction";

import BSCSLogo from '~/images/lesson_4_opener.jpg';



const Page = () => {
  return (
      <LessonLayout
        current_step={0}
        lesson={"Lesson 4"}
        color={"ms"}
        total_count={15}
        module_title="What Could Happen? "
      >
        <Introduction
          alt="tablet"
          image={BSCSLogo}
          description="Making decisions about science and health can be hard for everyone. There are rules to help people know if health products are safe and effective. In this lesson, you will learn some of the government regulations that companies must obey when they advertise medications. You will also think about possible problems that people need to think about before they use a medication or get a health treatment."
        />

      </LessonLayout>
  )
}

export default Page;
